// 全局组件配置

import empty from "./empty/Main"; // 空状态组件
import Header from "@/components/header/Header"; // 头部组件
import Footer from "@/components/footer/Footer"; // 底部栏

empty.install = function (Vue) {
  Vue.component("empty", empty);
};

Header.install = function (Vue) {
  Vue.component("BaseHeader", Header);
};

Footer.install = function (Vue) {
  Vue.component("BaseFooter", Footer);
};

// 引用本js中所有的组件
export function InstallAll(Vue) {
  Vue.use(empty);
  Vue.use(Header);
}
