import axios from "axios";
import router from "@/router/index";
import { localGet } from "./index";
export const Method = {
  GET: "get",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
};

const service = axios.create({
  // baseURL: "http://localhost:3000/api",
  baseURL: "https://api.floorex-eu.com/api",
  timeout: 10 * 1000, // 请求超时时间
  headers: { "Content-Type": "application/json;charset=UTF-8" },
});

service.interceptors.request.use(
  (config) => {
    const token = localGet("token") || "";
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// 请求拦截器，内部根据返回值，重新组装，统一管理。
service.interceptors.response.use(
  (response) => response.data.data,
  (error) => {
    const responseData = error.response?.data?.data;
    if (error.response && error.response.status === 401) {
      router.push("/login"); // 跳转到login页面
    }
    return Promise.reject(error);
  }
);

export default service;
