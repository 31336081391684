
// 全部商品分类
export const SET_CATEGORY = (state, data) => {
  state.category = data
}
export const SET_NAVLIST = (state, data) => {
  state.navList = data
}
export const SET_CARTNUM = (state, data) => {
  state.cartNum = data
}
export const SET_HOTWORDS = (state, data) => {
  state.hotWordsList = data
}
export const SET_LOGOIMG = (state, data) => {
  state.logoImg = data
}
export const SET_SITENAME = (state, data) => {
  state.siteName = data
}