import Vue from "vue";
import Vuex from "vuex";
import * as actions from "./actions";
import * as mutations from "./mutations";
import * as getters from "./getters";
import storage from "@/plugins/storage.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    navList: [], // 首页快捷导航
  },
  getters,
  actions,
  mutations,
});
