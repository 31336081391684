import Cookies from "js-cookie";

export default {
  setItem: (key, value, options = {}) => {
    Cookies.set(key, value, options);
  },
  getItem: (key) => {
    return Cookies.get(key);
  },
  removeItem: (key, options = {}) => {
    Cookies.remove(key, options);
  },
};
