import Vue from "vue";
import Router from "vue-router";

const Login = (resolve) => require(["@/pages/Login"], resolve);

const GoodsList = (resolve) => require(["@/pages/GoodsList"], resolve);
const GoodsDetail = (resolve) => require(["@/pages/GoodsDetail"], resolve);

const ShoppingCart = (resolve) => require(["@/pages/ShoppingCart"], resolve);

const Cart = (resolve) => require(["@/pages/Cart"], resolve);
const Order = (resolve) => require(["@/pages/OrderConfirm"], resolve);

const IndexPage = (resolve) => require(["@/pages/Index"], resolve);
/*
 * 会员中心
 * 订单列表
 */

/*
 * 会员中心
 */

/*
 * 会员中心
 * 账户中心
 * */

const Home = (resolve) => require(["@/pages/user/Home"], resolve);

/**
 * 店铺入驻
 */

Vue.use(Router);

/**
 * 解决重复点击菜单会控制台报错bug
 */
const routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "index",
      component: IndexPage,
    },
    {
      path: "/login", // 登陆
      name: "login",
      component: Login,
      meta: {
        title: "登录",
      },
    },

    {
      path: "/goodsList", // 商品列表
      name: "GoodsList",
      component: GoodsList,
    },
    {
      path: "/goodsDetail", // 商品详情
      name: "GoodsDetail",
      component: GoodsDetail,
      meta: { title: "商品详情" },
    },

    {
      path: "/shoppingCart", // 头部购物车
      name: "ShoppingCart",
      component: ShoppingCart,
    },
    {
      path: "/cart", // 购物车
      name: "Cart",
      component: Cart,
      meta: { title: "Shopping Cart" },
    },
    {
      path: "/order", // 购物车
      name: "order",
      component: Order,
      meta: { title: "Order" },
    },
    {
      path: "/home", // 主页
      component: Home,
      children: [
        // {
        //   path: "MyOrder",
        //   name: "MyOrder",
        //   component: MyOrder,
        //   meta: { title: "我的订单" },
        // },
        // {
        //   path: "OrderDetail",
        //   name: "OrderDetail",
        //   component: OrderDetail,
        //   meta: { title: "订单详情" },
        // },
      ],
    },
    // {
    //   path: "/merchant",
    //   name: "Merchant",
    //   component: Merchant,
    //   meta: { title: "店铺" },
    // },
  ],
});
